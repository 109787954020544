.resource-roadmap-container {
  max-width: 980px;
  display: flex;
}

.resource-roadmap-container .resource-roadmap {
  padding: 1.25rem 1.25rem;
  background: #fff;
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 1px #d4d4d5, 0 1px 0 0 #d4d4d5, 0 1px 3px 0 #bcbdbd;
}
