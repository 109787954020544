.routes .beta.ui.segment,
.routes .beta-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.routes .beta.ui.container {
  justify-items: center;
  flex-grow: 1;
}

.routes .beta.ui.segment {
  max-width: 1080px;
  padding: 3rem;
}
