@import '../../colors.css';

.twilio-video-component {
  display: flex;
  align-items: stretch;
  position: relative;
}

.twilio-video-component .volume-indicator-component,
.devices-form .volume-indicator-component {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
}
.twilio-video-component .volume-indicator-component .indicator,
.devices-form .volume-indicator-component .indicator {
  background-color: #21ba45;
  margin: 0 2px;
  width: 5px;
  max-height: 100%;
  border-radius: 5px;
}
.twilio-video-component .volume-indicator-component .indicator:first-child,
.devices-form .volume-indicator-component .indicator:first-child {
  margin-left: 0;
}
.twilio-video-component .volume-indicator-component .indicator:last-child,
.devices-form .volume-indicator-component .indicator:last-child {
  margin-right: 0;
}

.twilio-video-component .local-tracks-view {
  position: relative;
  line-height: 0;
}
.twilio-video-component .local-tracks-view video {
  width: 100%;
}
.twilio-video-component .local-tracks-view .volume-indicator-component,
.twilio-video-component
  .twilio-video-room-component
  .participant
  .volume-indicator-component {
  position: absolute;
  bottom: 1em;
  left: 1em;
}

.twilio-video-component .circular-button {
  cursor: pointer;
  border-radius: 24px;
  height: 48px;
  width: 48px;
  border: 2px solid #fff;
  background-color: transparent;
  margin: 0 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: 80ms ease-in-out circularButtonFadeOut;
}
.twilio-video-component .circular-button.red {
  background-color: #d01919;
  border-color: #d01919;
  animation: 80ms ease-in-out circularButtonFadeOutRed;
}
.twilio-video-component .circular-button:disabled {
  opacity: 0.5;
}
.twilio-video-component .circular-button:focus {
  outline: 0;
}
@media (any-hover: hover) {
  .twilio-video-component .circular-button:hover {
    background-color: #fff;
    border-color: #fff;
    animation: 80ms ease-in-out circularButtonFadeIn;
  }
  .twilio-video-component .circular-button:hover .icon {
    color: #888;
  }
}

@keyframes circularButtonFadeIn {
  from {
    background-color: transparent;
  }
  to {
    background-color: #fff;
  }
}

@keyframes circularButtonFadeOut {
  from {
    background-color: #fff;
  }
  to {
    background-color: transparent;
  }
}
@keyframes circularButtonFadeOutRed {
  from {
    background-color: #fff;
    border-color: #fff;
  }
  to {
    background-color: #d01919;
    border-color: #d01919;
  }
}

.twilio-video-component .circular-button .icon {
  color: #fff;
  margin: 0;
  font-size: 1.5em;
}

.twilio-video-component .local-preview-component {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.twilio-video-component .local-preview-component .preview {
  flex-grow: 1;
  max-width: 800px;
  position: relative;
  display: flex;
  line-height: 0;
  align-items: stretch;
  justify-content: stretch;
  background-color: #383838;
}
.twilio-video-component .local-preview-component .local-tracks-view {
  display: flex;
  align-items: center;
  width: 100%;
}
.twilio-video-component
  .local-preview-component
  .local-tracks-view
  .video-component {
  flex: 1;
}
.twilio-video-component .local-preview-component .preview video {
  width: 100%;
}
.twilio-video-component .twilio-video-room-component .settings,
.twilio-video-component .local-preview-component .settings {
  margin: 0;
  position: absolute;
  bottom: 1em;
  right: 1em;
}
.twilio-video-component .local-preview-component .ready {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.twilio-video-component .local-preview-component .ready .button,
.twilio-video-component .local-preview-component .media-error .button {
  white-space: nowrap;
  margin: 1em 0;
  font-size: 1.5rem;
  padding: 0.5em 2em;
  border-radius: 48px;
}
.twilio-video-component .local-preview-component .ready .button.de-emphasized {
  padding: 0;
  background-color: transparent;
  color: var(--primary-color);
  font-size: 1rem;
}
.twilio-video-component .local-preview-component .instructions {
  margin: 2em 0;
  max-width: 1200px;
  color: #0a3a48;
}
.twilio-video-component .local-preview-component .instructions .ui.cards {
  margin: -0.875em -1.5em;
}
.twilio-video-component .local-preview-component .instructions .ui.card {
  margin-left: 1.5em;
  margin-right: 1.5em;
  border-radius: 0;
  border: 2px solid var(--primary-color);
}
.twilio-video-component
  .local-preview-component
  .instructions
  .ui.cards.two
  .ui.card {
  width: calc(50% - 3em);
}
.twilio-video-component
  .local-preview-component
  .instructions
  .ui.cards.four
  .ui.card {
  width: calc(25% - 3em);
}
.twilio-video-component
  .local-preview-component
  .instructions
  .ui.card
  .content {
  padding: 2em;
  border: none;
  text-align: center;
}
.twilio-video-component
  .local-preview-component
  .instructions
  .ui.card
  .content
  .image {
  height: 70px;
  margin-bottom: 2em;
}
.twilio-video-component .local-preview-component .instructions .toggles {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 1em 0;
}
.twilio-video-component
  .local-preview-component
  .instructions
  .toggles:last-child {
  margin-bottom: 0;
}
.twilio-video-component .local-preview-component .instructions .toggles span {
  margin: 0 0.5em;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
}
.twilio-video-component .local-preview-component .instructions .toggles .icon {
  margin: 0;
}

.twilio-video-component .local-preview-component .instructions .toggles .off {
  background-color: #d01919;
  color: #ffffff;
}
.twilio-video-component .local-preview-component .instructions .toggles .on {
  border: 1px solid #0a3a48;
  margin-left: 2em;
}

.twilio-video-component .local-preview-component .media-error,
.twilio-video-component .local-preview-component .connecting {
  flex: 1;
}

.twilio-video-component .local-preview-component .media-error .call-me.button {
  margin: 0 auto;
  display: block;
}

.twilio-video-component .twilio-video-room-component {
  position: relative;
  justify-content: center;
  background-color: #383838;
  color: #fff;
}
.twilio-video-component .twilio-video-room-component.waiting {
  min-height: 300px;
}
.twilio-video-component .twilio-video-room-component .participants {
  min-height: 200px;
}
.twilio-video-component .twilio-video-room-component:not(.fullscreen) {
  border-radius: 5px;
}
.twilio-video-component .twilio-video-room-component,
.twilio-video-component .twilio-video-room-component .participants,
.twilio-video-component .twilio-video-room-component .participant,
.twilio-video-component .twilio-video-room-component .participant .tracks {
  flex: 1;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  padding: 0;
}
.twilio-video-component .twilio-video-room-component .notification {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 1em;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.twilio-video-component .twilio-video-room-component .participants {
  flex-direction: row;
  flex-wrap: wrap;
}
.twilio-video-component .twilio-video-room-component .participant {
  position: relative;
  line-height: 0;
}

.twilio-video-component
  .twilio-video-room-component.participants1
  .participant {
  flex: 1;
}
.twilio-video-component .twilio-video-room-component.participants2 .participant,
.twilio-video-component .twilio-video-room-component.participants3 .participant,
.twilio-video-component
  .twilio-video-room-component.participants4
  .participant {
  width: 50%;
}
.twilio-video-component .twilio-video-room-component.participants5 .participant,
.twilio-video-component .twilio-video-room-component.participants6 .participant,
.twilio-video-component .twilio-video-room-component.participants7 .participant,
.twilio-video-component .twilio-video-room-component.participants8 .participant,
.twilio-video-component
  .twilio-video-room-component.participants9
  .participant {
  width: 33%;
}
.twilio-video-component .twilio-video-room-component .participant .tracks {
  align-items: center;
  justify-content: center;
}
.twilio-video-component
  .twilio-video-room-component
  .participant
  .tracks
  video {
  flex: 1;
  width: 100%;
  object-position: center;
}
.twilio-video-component
  .twilio-video-room-component
  .participant:not(.has-video)
  .tracks
  video {
  visibility: hidden;
}
.twilio-video-component .twilio-video-room-component .participant .avatar {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  margin-left: -50px;
  margin-top: -50px;
}
.twilio-video-component
  .twilio-video-room-component
  .participant.has-video
  .avatar {
  display: none;
}
.twilio-video-component .twilio-video-room-component .participant .user {
  position: absolute;
  top: 2em;
  left: 3.5em;
}

.twilio-video-component .twilio-video-room-component .waiting-message {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.twilio-video-component .twilio-video-room-component .local-tracks-view {
  position: absolute;
  top: 0;
  right: 0;
}

.twilio-video-component
  .twilio-video-room-component
  .network-quality-indicator {
  position: absolute;
  left: 1em;
  top: 1em;
  height: 24px !important;
}

.twilio-video-component .controls {
  position: absolute;
  bottom: 1em;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.devices-form .microphone-preview {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.devices-form .microphone-preview .volume-indicator-component {
  margin-left: 0.5em;
}
.devices-form video {
  width: 100%;
}

@media (max-width: 800px) {
  .twilio-video-component .local-preview-component {
    flex-direction: column;
  }
}
