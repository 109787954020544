.password-registration-form.ui.form {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.password-registration-form .currently-employed {
  display: flex;
  align-items: center;
}

@media (max-width: 980px) {
  .password-registration-form.ui.form {
    max-width: 90%;
  }
}
