@import '../../colors.css';

.live-chat-standalone {
  width: 100%;
  animation: live-chat-standalone-fade-in 2s;
}

.live-chat-standalone .live-chat-fullscreen-copy {
  width: 30%;
  padding-right: 1em;
}

.live-chat-standalone .ui.header {
  font-family: 'AvenirNextLTProLight';
  margin: 1em 0;
}
.live-chat-standalone .ui.header .sub.header {
  margin: 0.25em 0;
}

.live-chat-standalone .guides {
  margin: 3em 0;
  text-align: center;
}

.live-chat-standalone .guides .ui.header {
  font-weight: normal;
}

.live-chat-standalone .guides,
.live-chat-standalone .guides .header,
.live-chat-standalone .hours {
  color: #888888;
}

.live-chat-standalone .guides ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0 auto;
  max-width: 250px;
}
.live-chat-standalone .guides .guide {
  width: 50%;
  margin-bottom: 1em;
}
.live-chat-standalone .guides .guide .avatar {
  width: 80px;
  height: 80px;
}
.live-chat-standalone .guides .guide .name {
  display: block;
}

.live-chat-standalone .hours {
  margin-top: 1em;
  text-align: center;
}
.live-chat-standalone .hours dt {
  margin-top: 1em;
}
.live-chat-standalone .hours dd {
  margin-inline-start: unset;
}

@keyframes live-chat-standalone-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
