/* theme- level css*/
@import './colors.css';

body {
  background-color: var(--background-color);
}

a {
  color: var(--primary-color);
}

.ui.loading.segment:before {
  background: none;
}

.ui.pagination.menu {
  background: transparent;
}

.ui.grid {
  margin: 0;
}

button.ui.button.primary {
  background-color: var(--primary-color);
  font-family: 'AvenirNextLTPro-Medium';
  border-radius: 3px;
}

button.ui.button.primary:hover {
  background-color: var(--primary-color);
  opacity: 0.9;
}

.ui.steps {
  border: none;
}
.ui.steps .step,
.ui.steps .step:first-child,
.ui.steps .step:last-child {
  margin: 0.75em;
  padding: 0;
  background-color: transparent;
}
.ui.step,
.ui.steps .step {
  font-size: 0.5rem;
  border: none;
}
.ui.ordered.steps .step:before {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background: rgba(34, 36, 38, 0.15);
  margin: 0;
}
.ui.ordered.steps .step:before,
.ui.steps .step .icon {
  color: rgba(34, 36, 38, 0.15);
}
.ui.ordered.steps .step:after {
  content: normal;
}
.ui.steps .step.active {
  background: transparent;
}
.ui.steps .step.active:before {
  background: #ffffff;
  border: 1px solid var(--primary-color);
}
.ui.ordered.steps .step.active:before,
.ui.steps .active.step .icon {
  color: var(--primary-color);
}

/*
.ui.form .continue-btn.field {
  margin-top: 1rem;
}

.continue-btn.field .ui.button {
  height: 48px;
  border-radius: 2px;
  background-color: var(--lg-blue-medium);
  color: #e2e2e2;
  border: 1px solid var(--lg-blue-medium);
}

.continue-btn.field .disabled.ui.button {
  background-color: var(--lg-blue-medium);
  color: var(--background-color);
}

.continue-btn.field .ui.button:hover {
  background-color: var(--lg-blue-light);
  color: var(--clickable-white-link-highlight);
  border: 1px solid var(--background-dark);
}

.continue-btn.ui.button:active {
  background-color: var(--lg-blue-dark);
}*/

i.icon.blue {
  color: #275ce0 !important;
}

.ui.blue.menu .active.item,
.ui.menu .blue.active.item {
  border-color: #275ce0;
  color: #275ce0;
}

i.inverted.bordered.blue.icon,
i.inverted.circular.blue.icon,
i.inverted.blue.icon,
.ui.blue.button,
.ui.blue.buttons .button,
.ui.blue.label,
.ui.inverted.blue.segment,
.ui.primary.button,
.ui.primary.buttons .button {
  background-color: #275ce0;
}

.ui.blue.button,
.ui.blue.buttons .button,
.ui.primary.button,
.ui.primary.buttons .button {
  transition: background-color 250ms linear;
}
.ui.blue.button:hover,
.ui.blue.buttons .button:hover,
.ui.primary.button:hover,
.ui.primary.buttons:hover {
  background: rgb(28, 44, 218);
  transition: background-color 250ms linear;
}

.ui.basic.blue.button {
  background-color: #fff;
  color: #275ce0;
}

.ui.blue.header {
  color: #275ce0;
}

.ui.active.visible.floating.top.right.pointing.dropdown.icon:focus-within
  i.icon.big.setting.big {
  color: #959595;
}

input[type='radio'] {
  width: 17px;
  height: 17px;
}

.ui.button {
  border-radius: 0.18em;
  font-size: 0.9rem;
  font-weight: normal;
  padding: 1.1em 1.7em;
}
/* 
i.large.icon,
i.large.icons {
  line-height: 0.6;
} */

.ui.sidebar {
  overflow-y: visible !important;
}

.ui.modal {
  margin: 0 !important;
}

.ui.tiny.modal {
  margin-top: 0 !important;
}

.ui.modal > .close {
  top: 1.0535rem;
  right: 1rem;
  color: var(--text-hollowbutton);
}

.ui.pagination.menu .active.item {
  color: rgb(39, 92, 224);
}

img.ui.image .lg-logo,
.ui.header > .image:not(.icon),
.ui.header > img {
  display: inline-block;
  margin-top: 0.14285714em;
  width: 150px;
  vertical-align: middle;
  margin: 12px 0;
  max-width: none;
}

.ui.ui.top.thin.visible.sidebar.menu {
  height: 65px !important;
}

.meta {
  color: #272727a8 !important;
}

.content .description + .header {
  color: rgb(102, 102, 102) !important;
}

.content {
  color: rgb(108, 109, 109) !important;
}

.description,
.description span {
  color: #000000ad;
}

.description i.huge.icon {
  color: rgb(102, 102, 102) !important;
}

.sub.header span,
.sub.header {
  color: rgb(68, 67, 67);
}

.ui.form input::placeholder,
.ui.dropdown:not(.button) > .default.text {
  color: rgb(118, 118, 118);
}

.skip {
  height: 35px;
  border: #daffff 3px double;
  background: rgb(39, 92, 224);
  color: white;
  display: flex;
  width: 160px;
  top: -40px;
  justify-content: center;
  align-items: center;
  text-decoration: underline solid white;
  transition: top 1s ease-out;
}
.skip:hover,
.skip:focus {
  border: yellow 3px double;
  color: yellow;
  text-decoration: underline solid yellow;
  top: 5px;
  left: 5px;
  z-index: 103 !important;
  position: fixed;
  display: flex;
  transition: top 0.1s ease-in;
}

.skip:active {
  border: #daffff 3px double;
  text-decoration: underline solid #daffff;
  color: #daffff;
  display: flex;
}

div.app-container > div:first-child {
  display: flex;
}

div#content div div.ui.basic.segment {
  padding-top: 0;
}

.content .description i.huge.icon,
i.huge.icons {
  line-height: 1;
  font-size: 3em;
}

/*Checks and Radios*/

.ui.checkbox input ~ .box:before,
.ui.checkbox input ~ label:before {
  background: var(--check-radio-unselected-background);
  border-color: var(--check-radio-unselected-background);
  transition: all 150ms;
}

.ui.checkbox input:focus ~ .box:before,
.ui.checkbox input:focus ~ label:before,
.ui.checkbox input:hover ~ .box:before,
.ui.checkbox input:hover ~ label:before {
  background: var(--check-radio-unselected-background);
  border-color: var(--lg-blue-light);
}

.ui.checkbox input:checked ~ .box:before,
.ui.checkbox input:checked ~ label:before,
.ui.checkbox input:checked:focus ~ .box:before,
.ui.checkbox input:checked:focus ~ label:before {
  background: var(--lg-blue-medium);
  border-color: var(--check-radio-unselected-background);
}

.ui.radio.checkbox input:checked ~ .box:before,
.ui.radio.checkbox input:checked ~ label:before,
.ui.radio.checkbox input:checked:focus ~ .box:before,
.ui.radio.checkbox input:checked:focus ~ label:before {
  background: var(--lg-blue-medium);
  border-color: var(--check-radio-unselected-background);
}

.ui.checkbox input:checked:hover ~ .box:before,
.ui.checkbox input:checked:hover ~ label:before,
.ui.checkbox input:checked:focus:hover ~ .box:before,
.ui.checkbox input:checked:focus:hover ~ label:before {
  border-color: var(--lg-blue-light);
}

.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after,
.ui.checkbox input:checked:focus ~ .box:after,
.ui.checkbox input:checked:focus ~ label:after {
  color: var(--clickable-white-link-highlight);
  content: '\f00c';
  font-weight: 900;
  font-family: 'Line Awesome Free';
  font-size: 0.8rem;
}

.ui.radio.checkbox input:checked ~ .box:after,
.ui.radio.checkbox input:checked ~ label:after,
.ui.radio.checkbox input:checked:focus ~ .box:after,
.ui.radio.checkbox input:checked:focus ~ label:after {
  background-color: var(--clickable-white-link-highlight);
}

/* Pagination */

.ui.pagination.menu {
  margin: 1em;
  display: flex;
  justify-content: center;
  align-content: center;
  border: 0;
  box-shadow: none;
}

.ui.pagination.menu .item {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-content: center;
}

.TM {
  font-size: 0.7rem;
  padding: 0.1rem;
}

/* semantic ui pagination styles */
.ui.menu {
  border-bottom: 0 none !important;
  box-shadow: none;
  font-size: small;
}

.ui.secondary.pointing.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  place-content: center;
  padding-bottom: 10px;
}

.ui.secondary.pointing.menu .item {
  color: var(--primary-color);
  padding: 4px;
  justify-content: center;
  text-align: center;
  border: none;
}

.ui.secondary.pointing.menu a.item:hover {
  background-color: transparent;
  color: blue;
  border: none;
}

.ui.secondary.pointing.menu .active.item.item {
  color: white;
  border: none;
  border-radius: 30px;
  background-color: var(--primary-color);
  padding: 4px;
}

/* Lato override */
h1,
h2,
h3,
h4,
h5,
.ui.header,
.ui.modal > .header,
.ui.message .header,
.ui.cards > .card > .content > .header,
.ui.card > .content > .header,
.ui.list .list > .item .header,
.ui.list > .item .header,
.ui.steps .step .title,
.ui.popup > .header,
.ui.search > .results > .message .header,
.ui.items > .item > .content > .header {
  font-family: 'AvenirNextLTPro-Medium';
  font-weight: 700;
}
.ui.form input:not([type]),
.ui.form input[type='date'],
.ui.form input[type='datetime-local'],
.ui.form input[type='email'],
.ui.form input[type='number'],
.ui.form input[type='password'],
.ui.form input[type='search'],
.ui.form input[type='tel'],
.ui.form input[type='time'],
.ui.form input[type='text'],
.ui.form input[type='file'],
.ui.form input[type='url'],
.ui.menu,
.ui.button,
.ui.text.container,
.ui.input > input {
  font-family: 'AvenirNextLTPro-Medium';
}
