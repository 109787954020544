.guide-video .guide-video-preview {
  @apply text-xl font-bold leading-8;
}

.guide-video .guide-video-preview i {
  @apply text-4xl;
}

.guide-video .guide-video-preview span {
  @apply align-text-bottom leading-8;
}
