.client-details .profile-fields {
  display: flex;
  flex-flow: row wrap;
}
.client-details .ui.list.profile-fields > .item.profile-field {
  padding: 0.5em;
  flex-basis: 50%;
}

.client-details .actions {
  text-align: right;
}

.client-details .button.save {
  width: 162px;
  height: 40px;
  background: linear-gradient(
    90deg,
    rgba(67, 167, 119, 1),
    rgba(38, 109, 179, 1)
  ) !important;
  color: #fff !important;
  border-radius: 20px !important;
}

/**/
.client-details {
  display: flex;
  flex-direction: column;
}
