.channel-resources {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-self: center;
  max-width: 980px;
  min-width: 290px;
  padding-bottom: 1rem;
  /* background: purple; */
}

.channel-resources .resources-loader.ui.segment {
  align-self: center;
  display: flex;
  justify-self: center;
  margin: 0 auto;
}

.channel-resources .resource-menu {
  align-items: flex-start;
  display: flex;
  width: 100%;
}

.channel-resources .resource-menu .tab-navigation,
.channel-resources .resource-menu .tab-navigation > .ui.menu {
  display: flex;
  width: 100%;
}

.channel-resources .resource-menu .select-navigation {
  display: flex;
  margin: 1rem 0;
}

.channel-resources .resources-content {
  align-content: center;
  /* background: rgba(0, 128, 255, 0.1); */
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-grow: 1;
}

.resource-container {
  display: flex;
  flex-direction: column;
}
