.resource-header.ui.header {
  margin: 1.25rem 0.5rem;
  display: flex;
  align-items: center;
}

.resource-header .back-button.ui.button {
  padding: 0.5rem;
  font-size: 1.25rem;
}

.resource-header-container {
  opacity: 0.9;
}

.resource-header-container.hovering {
  cursor: pointer;
  opacity: 1;
}

.resource-header-container .back-button.ui.button {
  transition: background-color 500ms, color 500ms;
}

.resource-header-container.hovering .back-button.ui.button {
  background-color: #346eff;
  color: #dce5fd;
  border: 1px #346eff solid;
  font-size: 1.25rem;
  padding: 0.5rem;
}
