.dash-avenir {
  font-family: 'AvenirNextLTPro-Medium';
  font-size: 0.9rem;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.dash-avenir.strong {
  font-family: 'AvenirNextLTPro-Medium';
  font-size: 10px;
  font-weight: 600;
}

.dashboard {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  max-width: 1024px;
}

/*Loader*/
.dashboard .dashboard-loader {
  display: flex;
  flex-grow: 1;
  align-self: center;
}

.dashboard .dashboard-loader.ui.loading.segment:before {
  background: none;
}

/* PLACEHODLERS */
.dashboard .sessions-placeholder {
  align-items: center;
  background-color: #ffffff;
  border-radius: 5px;
  color: #3b404d;
  display: flex;
  justify-content: center;
  min-height: 60px;
}

/* SEE MORE */
.dashboard .seemore {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.dashboard .see-more-button {
  margin-top: 0em;
  padding: 0.7em 1.5em;
  line-height: 1em;
}

/* HEADER */
.dashboard .ui.large.header {
  font-family: 'AvenirNextLTPro-Medium';
  font-weight: 600;
  font-size: 1.4rem;
  margin-top: 0.5rem;
}

.dashboard .sessions-count {
  font-size: 1.4rem;
  color: #3b404d;
  font-family: 'AvenirNextLTProLight';
  font-weight: 400;
  padding: 0 0.5rem;
}

.dashboard .see-more-button {
  margin-top: 0em;
  padding: 0.7em 1.5em;
  line-height: 1em;
}
