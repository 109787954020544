/*TOS Privacy Agreement CSS for the password registration form*/

/*prevent 'i agree' text wrapping*/
.tos-pp-container {
  /* min-width: max-content; */
}

/*checkbox + agreement label*/
.tos-pp-container > .field {
  padding-right: 0.2rem !important;
}

.tops-pp-container .label {
  flex-wrap: nowrap;
  display: flex;
  flex-shrink: 0;
}

/*label for the TOS + PP links and text*/
.tos-pp-text {
  display: flex;
  flex-shrink: 0;
  flex-wrap: nowrap;
}

.tos-pp-text a {
  display: flex;
  padding: 0rem 0.2rem;
  flex-wrap: nowrap;
}

/*error label styling*/

.tos-privacy-agreement-container + .red.label.pointing.basic {
  display: flex;
  max-width: 140px;
  text-align: center;
}
