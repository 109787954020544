.react-rte {
  /* min-height: 23.5rem; */
  font-family: Helvetica, Arial, sans-serif !important;
}

.react-rte .public-DraftEditor-content {
  min-height: 110px;
}

/* .react-rte h1 {
  @apply text-4xl my-6;
}
.react-rte h2 {
  @apply text-3xl my-5;
}
.react-rte h3 {
  @apply text-2xl my-4;
} */

.react-rte h1,
.rich-text h1,
.ck h1 {
  @apply text-4xl my-6;
}
.react-rte h2,
.rich-text h2,
.ck h2 {
  @apply text-3xl my-5;
}
.react-rte h3,
.rich-text h3,
.ck h3 {
  @apply text-2xl my-4;
}

.react-rte strong,
.rich-text strong,
.ck strong {
  @apply font-bold;
}
.react-rte ol,
.rich-text ol,
.react-rte ul,
.rich-text ul,
.ck ol,
.ck ul {
  @apply list-inside;
}
.react-rte ul,
.rich-text ul,
.ck ul {
  @apply list-disc;
}
.react-rte ol,
.rich-text ol,
.ck ol {
  @apply list-decimal;
}

.react-rte blockquote,
.rich-text blockquote,
.ck blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: 5px solid #ccc;
}
