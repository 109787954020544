.powerful,
.powerful.button {
  align-items: center;
  /* background-image: linear-gradient(293deg, #3f74fc, #03a6b9); */
  /* background-image: linear-gradient(293deg, #ff00fc, #03a6b9); */
  background-image: linear-gradient(293deg, #0840ce, #55d2e0);
  border-radius: 20px;
  border: 0px #fff solid;
  color: #fff;
  display: flex;
  justify-content: center;
  min-height: 34px;
  min-width: 105px;
  background-size: 250% 250%;
  transition: border 500ms;
}

.powerful.main.button {
  /* width: 200px;
  height: 45px; */
  border-radius: 5px;
  background-image: linear-gradient(282deg, #3f74fc, #03a6b9);
  background-size: 250% 250%;
  transition: border 500ms;
  align-items: center;
  display: flex;
  color: #ffffff;
  font-family: 'AvenirNextLTPro-Medium';
  font-weight: 700;
}

.powerful.animated {
  -webkit-animation: PowerGradient 8s ease infinite;
  -moz-animation: PowerGradient 8s ease infinite;
  animation: PowerGradient 8s ease infinite;
}

.powerful.button {
  border: 1px #dce5fd solid;
  cursor: pointer;
}

.powerful.button:hover {
  border-color: #03a6b9;
  -webkit-animation: PowerGradient 4s ease infinite;
  -moz-animation: PowerGradient 4s ease infinite;
  animation: PowerGradient 4s ease infinite;
  background-image: linear-gradient(293deg, #0840ce, #55d2e0);
  color: white;
}

/*countdown animation*/

@-webkit-keyframes PowerGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes PowerGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes PowerGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
