.admin-user-import-upload .dropzone {
  position: relative;
  padding: 2em;
  background-color: #fff;
  border: 1px dotted rgb(30, 88, 235);
}

.admin-user-import-upload .dropzone.active {
  border-style: solid;
  border-color: #21ba45;
}

.admin-user-import-upload .dropzone.reject {
  border-style: solid;
  border-color: #db2828;
}

.admin-user-import-upload .dropzone .overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
