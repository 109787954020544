.call-container {
  width: 100%;
}
.call-container .is-guide {
  max-width: 800px;
}

.call-container .client-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.call-container .client-faceplate-guide-actions {
  display: flex;
  flex-direction: column;
}

.call-container .client-header-container .controls {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 900px) {
  .call-container .call-container-row .call-client {
    display: flex;
    padding: 0;
    margin: 0;
  }
}

.call-container .video {
  display: flex;
}
.call-container .video .twilio-video-component {
  flex-grow: 1;
}

.call-container .sidebar {
  max-width: 400px;
  animation: 125ms ease-in-out sideBarFadeIn;
  overflow: hidden;
  margin-left: 1em;
}
.call-container .sidebar.hidden {
  display: none;
  height: 0px;
  animation: 125ms ease-in-out sideBarFadeOut;
}
.call-container .sidebar .ui.segment {
  border-radius: 1rem;
  background-color: #f1f6fa;
  padding: 1rem;
  margin-bottom: 1em;
}

.call-container .reason {
  margin-bottom: 2em;
}
.call-container .reason label {
  color: #888888;
  float: left;
  margin-right: 1em;
}

.call-container .client-post-call {
  text-align: center;
}

.call-container .client-post-call .segment {
  margin: 1em auto;
}

.crisis-protocol-text {
  padding: 0rem 1rem;
}

.call-client-header {
  font-family: 'AvenirNextLTPro-Medium';
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3b404d;
}

@media (max-width: 1100px) {
  .call-container .video {
    flex-direction: column-reverse;
  }
}

@keyframes sideBarFadeIn {
  from {
    opacity: 0;
    transform: translateY(-90px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes sideBarFadeOut {
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(-90px);
  }
}
