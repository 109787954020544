.admin-applications {
  display: flex;
  flex-direction: column;
  min-width: 520px;
}

@media (max-width: 801px) {
  .admin-applications {
    min-width: 320px;
  }
}

@media (max-width: 480px) {
  .admin-applications {
    min-width: auto;
  }
}
