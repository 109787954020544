.pp-title {
  font-size: 2rem;
  margin: 10px 0;
}

.pp-subtitle {
  color: #49b8a4;
  font-size: 1.5rem;
  margin: 10px 0;
}

.pp-small-title {
  color: #49b8a4;
  font-size: 1.25rem;
  margin: 10px 0;
}

.pp-section {
  margin-top: 15px;
}

.updated-date {
  color: #49b8a4;
}

.list {
  list-style: inside;
  margin-top: 10px;
  padding-left: 15px;
}
