.profile-steps-progress {
  display: flex;
  flex-direction: column;
}

.profile-steps-progress .ui.progress {
  background: #fff;
  border-radius: 1.5rem;
  margin-bottom: 5px;
}

.profile-steps-progress .ui.progress .bar {
  background-image: linear-gradient(95deg, #4c7cf9, #346eff);
  border-bottom-left-radius: 1.5rem;
  border-top-left-radius: 1.5rem;
}

.profile-steps-progress .incomplete-btn {
  align-items: center;
  background-color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  font-size: 1.3em;
  height: 80px;
  margin: 0.2em 0em;
  padding: 0;
}

.profile-steps-progress .incomplete-btn:hover {
  background-color: #fff;
}

.profile-steps-progress .incomplete-btn .circular.icon {
  align-items: center;
  background-color: #96a3c1;
  box-shadow: none;
  color: #fff;
  display: flex;
  height: 1.8em !important;
  justify-content: center;
  margin: 0em 0.7em !important;
  padding: 0 !important;
  width: 1.8em !important;
}

.profile-steps-progress .progress-label {
  color: #3b404d;
  font-family: 'AvenirNextLTPro-Medium';
  font-size: 0.9rem;
  margin: 0.5em 0em;
}

.profile-steps-progress .progress-label .bold {
  color: #3b404d;
  font-family: 'AvenirNextLTPro-Medium';
  font-weight: 700;
  font-size: 0.9rem;
}
