.home-topic-groups-component .topic-group {
  height: 275px;
}
.home-topic-groups-component .topic-group img {
  width: 70%;
  animation: 200ms ease-in-out topicGroupImageIn;
}
.home-topic-groups-component .topic-group h4 {
  flex-grow: 1;
}
.home-topic-groups-component .topic-group .topics {
  display: none;
  height: 0;
  animation: 350ms ease-in-out topicIn;
}
.home-topic-groups-component .topic-group .topics li::before {
  content: '• ';
}
.home-topic-groups-component .topic-group .topics li:last-child::before {
  content: '+ ';
}

.home-topic-groups-component .topic-group.selected img,
.home-topic-groups-component .topic-group:hover img {
  width: 20%;
  animation: 200ms ease-in-out topicGroupImageOut;
}
.home-topic-groups-component .topic-group.selected h4,
.home-topic-groups-component .topic-group:hover h4 {
  flex-grow: 0;
}
.home-topic-groups-component .topic-group.selected .topics,
.home-topic-groups-component .topic-group:hover .topics {
  display: block;
  height: auto;
  animation: 350ms ease-in-out topicOut;
}

@keyframes topicGroupImageIn {
  from {
    width: 20%;
  }
  to {
    width: 70%;
  }
}

@keyframes topicGroupImageOut {
  from {
    width: 70%;
  }
  to {
    width: 20%;
  }
}

@keyframes topicIn {
  from {
    height: auto;
  }
  to {
    height: 0;
  }
}

@keyframes topicOut {
  from {
    height: 0;
  }
  to {
    height: auto;
  }
}
