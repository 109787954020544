.payment-options-component .cards,
.payment-options-component .charges,
.payment-options-component .subscriptions {
  margin-bottom: 1em;
}

.payment-options-component .subscriptions .message {
  text-align: center;
}

.payment-options-component .subscriptions .message .button {
  display: block;
  margin: 1em auto;
}

.payment-options-component .subscriptions .message .button .content {
  color: #ffffff !important;
}

.payment-options-component .subscriptions .message .note {
  margin-left: 1em;
  color: #888888;
}

.add-subscription-dialog .dropdown {
  margin-bottom: 1em;
}
