.session-notes {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 1.5rem 0rem;
}

.session-notes .previous-notes {
  cursor: pointer;
}

.session-notes .previous-notes > .ui.label {
  float: right;
}
