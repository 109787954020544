.call-request-form-bookingtime-selector {
  display: flex;
  flex-direction: column;
  /* flex-grow: 1; */
  justify-content: flex-start;
  align-items: center;
}

.call-request-form-bookingtime-selector .controls {
  display: flex;
  flex-direction: column;
}

.call-request-form-bookingtime-selector .controls.field {
  margin-bottom: 2px;
}

.call-request-form-bookingtime-selector .tz-input {
  align-self: flex-start;
}

.bookingtime-selector-inlinecalendar {
  min-width: 330px;
  padding-left: 0 !important;
  justify-content: flex-start !important;

  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.bookingtime-selector-timeline {
  flex-grow: 1;
  align-items: flex-end;
  padding-bottom: 0;
  padding: 0 0 16px 0px !important;
  overflow: hidden;
  min-height: 50px;
}

.bookingtime-selector-timeline .scheduler {
  margin: 5px auto;

  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.create-call-time-selector {
  display: flex;
  flex-direction: column;
}

.bookingtime-selector-timeline .scheduler-view {
  border: 1px solid #e9e9e9;
  margin: 0 0 0 -1px;
  padding: 0;
  max-width: 875px;
}
.bookingtime-selector-timeline #RBS-Scheduler-root > thead {
  display: none;
}
