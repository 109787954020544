.session-notes-dialog > .content:first-child {
  padding-bottom: 0;
  display: flex;
  flex-direction: row;
}

.session-notes-dialog .ui.header:first-child {
  flex: 1;
}

.session-notes-dialog .ui.header {
  margin: 0;
  line-height: 2rem;
}

.session-notes-dialog .ui.avatar {
  width: 2rem;
  height: 2rem;
  margin: 0 0 0 0.5em;
}
