.ui.avatar.image {
  position: relative;
  border-radius: 0;
  overflow: hidden;
}
.ui.avatar.image img {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: auto;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}
.guide-card.ui.card .avi-frame {
  padding: 0;
  height: 130px;
}
/*Fancy Light*/
.avi-frame {
  --g1-u: 1px;
  --g2-u: 6px;
  --gs-u: 2px;
  --glow-color: #11c440;
  --g-rgb: 17, 196, 64;
  --g-rgba: rgba(var(--g-rgb, 1));
  --s-rgba: rgba(255, 255, 255, 0.3);
  --s-glow-rgba: rgba(255, 255, 255, 0.5);
  --shine-width: 30%;
  --shine-pos: 15%;
  --g1: 0 0 calc(1 * var(--g1-u)) var(--g-rgba),
    0 0 calc(3 * var(--g1-u)) var(--g-rgba),
    0 0 calc(6 * var(--g1-u)) var(--g-rgba),
    0 0 calc(9 * var(--g1-u)) var(--g-rgba);
  --g2: 0 0 calc(1 * var(--g2-u)) var(--g-rgba),
    0 0 calc(3 * var(--g2-u)) var(--g-rgba),
    0 0 calc(6 * var(--g2-u)) var(--g-rgba),
    0 0 calc(9 * var(--g2-u)) var(--g-rgba);
  --gs1: 0 0 calc(1 * var(--gs-u)) var(--s-glow-rgba),
    0 0 calc(2 * var(--gs-u)) var(--s-glow-rgba),
    0 0 calc(3 * var(--gs-u)) var(--s-glow-rgba),
    0 0 calc(4 * var(--gs-u)) var(--s-glow-rgba);
  --gs2: 0 0 calc(1 * var(--gs-u)) var(--s-glow-rgba),
    0 0 calc(2 * var(--gs-u)) var(--s-glow-rgba),
    0 0 calc(4 * var(--gs-u)) var(--s-glow-rgba),
    0 0 calc(8 * var(--gs-u)) var(--s-glow-rgba);
}
.light-holder {
  position: relative;
  width: 100%;
  --delay: 250ms;
  --total: 500ms;
  --endpos: 45deg;
}
.light-holder > .light-anchor {
  position: absolute;
  transform: rotate(calc(var(--endpos) * -4));
  transition: transform var(--total) ease-in-out var(--delay),
    opacity calc(var(--total) * 0.25) ease-in-out var(--delay);
  opacity: 0.2;
  z-index: 2;
}
.light-holder .featured {
  position: absolute;
  backface-visibility: hidden;
  transform: scale(0.1) rotate(calc(var(--endpos) * -4 * -1));
  transition: transform var(--total) ease-in-out var(--delay);
  opacity: 1;
  z-index: 999;
  border: 1px solid #84eaa8;
  border-radius: 100%;
  border-color: #6ede87;
  background-color: #84eaa8;
  animation: glow 3s ease-in-out infinite;
}
.light-holder .featured::after {
  position: absolute;
  top: var(--shine-pos);
  right: var(--shine-pos);
  border-radius: 100%;
  background-color: var(--s-rgba);
  width: var(--shine-width);
  height: var(--shine-width);
  animation: glow-shine 3.5s ease-in-out infinite;
  content: ' ';
}
.light-holder.show > .light-anchor {
  transform: rotate(var(--endpos));
  opacity: 1;
}
.light-holder.show .featured {
  transform: scale(1) rotate(calc(var(--endpos) * -1));
}
@keyframes glow {
  0% {
    box-shadow: var(--g1);
    -webkit-box-shadow: var(--g1);
  }
  66% {
    box-shadow: var(--g2);
    -webkit-box-shadow: var(--g2);
  }
  100% {
    box-shadow: var(--g1);
    -webkit-box-shadow: var(--g1);
  }
}
@keyframes glow-shine {
  0% {
    box-shadow: var(--gs1);
    -webkit-box-shadow: var(--gs1);
  }
  66% {
    box-shadow: var(--gs2);
    -webkit-box-shadow: var(--gs2);
  }
  100% {
    box-shadow: var(--gs1);
    -webkit-box-shadow: var(--gs1);
  }
}
