/* Specific to override specificty of semantic default selector */
.concierge-create-call-request-form {
  display: flex;
  flex-grow: 1;

  flex-direction: column;
  min-height: 300px;
}

.concierge-create-call-request-form .circular-avi {
  height: 150px;
  width: 150px;
  background-position: center;
  background-size: 110%;
  border-radius: 96px;
  background-color: #eeeeee;
  flex-shrink: 0;
  background-size: cover;
}

.concierge-create-call-request-form .info-box {
  display: flex;
  flex-grow: 1;
  min-height: 26px;
  align-content: center;
}

.concierge-create-call-request-form .info-box > .ui.label {
  display: flex;
}

.concierge-create-call-request-form .info-box.empty {
  background-color: transparent;
}
