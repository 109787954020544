.guide-application .ui.steps {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.guide-application .ui.steps {
  margin-bottom: 0.75rem;
}

.guide-application .ui.steps .step {
  font-size: 0.35rem;
  flex: 0;
}

.guide-application .ui.steps .step:before {
  width: 1.75rem;
  height: 1.75rem;
}

.guide-application .social-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.guide-application .social-buttons > .facebook,
.guide-application .social-buttons > .google {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 48%;
}

.guide-application .social-buttons > .facebook > .ui.button,
.guide-application .social-buttons > .google > .ui.button {
  height: 40px;
  border-radius: 2px;
  border: solid 1px #c5cfd6;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  padding: 0;
  box-shadow: none;
  flex-grow: 1;
}

.guide-application .social-buttons .google-logo,
.guide-application .social-buttons .facebook-logo {
  width: 20px;
  height: 20px;
  margin-right: 1rem;
}

@media (max-width: 980px) {
  .guide-application .social-buttons {
    width: 90%;
  }
}
