html,
body {
  display: flex;
  flex: 1;
}

#root {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.intercom-lightweight-app {
  z-index: 10 !important;
}
