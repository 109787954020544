.loading-spinner {
  border-top-color: var(--lg-blue-medium);
  -webkit-animation: loading-spinner 1.5s linear infinite;
  animation: loading-spinner 1.5s linear infinite;
}

@-webkit-keyframes loading-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
