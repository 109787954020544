.tac-title {
  font-size: 2rem;
  margin: 10px 0;
}

.tac-subtitle {
  color: #49b8a4;
  font-size: 1.5rem;
  margin: 10px 0;
}

.tac-small-title {
  color: #49b8a4;
  font-size: 1.25rem;
  margin: 10px 0;
}

.tac-section {
  margin-top: 15px;
}

.list {
  margin-top: 10px;
  padding-left: 15px;
  list-style: inside;
}

.numbered-list {
  margin-top: 10px;
  padding-left: 30px;
  list-style: decimal;
}
