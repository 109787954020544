.my-sessions-component {
  width: 100%;
  max-width: 1280px;
}

.my-sessions-component .scheduled-sessions {
  margin-bottom: 6em;
}

.my-sessions-component .empty-state {
  text-align: center;
}
