.profile-step .incomplete-btn:hover .circular.icon {
  background-color: #346eff;
}

.profile-step .incomplete-btn .circular.icon .icon:before {
  font-size: 35px;
}

.profile-step .incomplete-text-container {
  display: flex;
  flex-direction: column;
}

.profile-step .incomplete-btn-title {
  display: flex;
  width: 186px;
  height: 23px;
  font-family: 'AvenirNextLTPro-Medium';
  font-size: 17px;
  font-weight: 500;
  color: #5d657b;
  display: flex;
  justify-content: left;
  align-items: center;
}

.profile-step .incomplete-btn:hover span.incomplete-btn-title {
  color: #346eff;
}

.profile-step .incomplete-btn-subtitle {
  display: flex;
  width: 99px;
  height: 14px;
  font-family: 'AvenirNextLTPro-Medium';
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3b404d;
}

.profile-step .call-entry-list .pointing.icon .setting.icon {
  position: relative;
}
