.resource-subchannels .subchannel-content {
  display: flex;
  flex-direction: row;
}

.resource-subchannels .subchannel-content.tablet {
  flex-direction: column;
}

.resource-subchannels .subchannel-facets {
  display: flex;
  flex-direction: column;
  min-width: 280px;
}

.resource-subchannels .subchannel-facets.collapseable {
  flex-direction: row;
  flex-grow: 1;
}

.resource-subchannels .subchannel-articles {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem;
}

.resource-subchannels .subchannel-articles.tablet {
  flex-direction: row;
}
