.scheduler-component .calendar-selector .react-datepicker {
  border: none;
}

.scheduler-component .calendar-selector .react-datepicker__header {
  background-color: transparent;
  border: none;
}

.scheduler-component .calendar-selector .react-datepicker__day-name {
  font-family: 'AvenirNextLTPro-Medium';
  @apply w-10 uppercase text-gray-400 text-lg;
}

.scheduler-component .calendar-selector .react-datepicker__day {
  @apply w-10 h-10 text-lg leading-9 rounded-full;
}

/* .scheduler-component .calendar-selector .react-datepicker__day--disabled {
  @apply text-gray-500;
}

.scheduler-component .calendar-selector .react-datepicker__day--selected,
.scheduler-component .calendar-selector .react-datepicker__day--keyboard-selected {
  @apply bg-gray-200 text-black;
} */

.scheduler-component
  .calendar-selector
  .react-datepicker__day--keyboard-selected {
  @apply bg-transparent;
}

.scheduler-component .calendar-selector.dirty .react-datepicker__day--selected {
  @apply bg-primary text-white;
}

.scheduler-component .calendar-selector .react-datepicker__day--highlighted {
  @apply bg-green-100 text-black;
}
