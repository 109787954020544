.admin-user .controls {
  margin: 0 0 2em 0;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.admin-user .controls .user-name {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.admin-user .controls .user-name .ui.avatar {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  height: 3em;
  width: 3em;
}

.admin-user .controls .user-name .ui.header {
  margin: 0;
  display: flex;
  flex-direction: column;
}

.admin-user .controls .companies {
  margin: 0 0.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
}
