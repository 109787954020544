/* guide Dashboard Specific CSS */
.dashboard-layout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 2rem;
}

.dashboard-layout .above-fold {
  display: flex;
  flex-direction: column;
  margin: 0 0 0.5rem 0;
}

.dashboard-layout .divide-fold {
  display: flex;
  height: 1px;
  border-bottom: 1px solid #dfdfdf;
}

.dashboard-layout .below-fold {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  flex-grow: 1;
}

.guide-details-header {
  display: flex;
  font-size: 1.75rem;
  font-family: 'AvenirNextLTPro-Medium';
  padding: 1rem 0;
}
.dashboard-layout .profile-steps-progress {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  max-width: auto;
  margin-left: 0rem;
  margin-top: 2rem;
}

@media (max-width: 980px) {
  .dashboard-layout {
    padding: 1rem;
  }
}
