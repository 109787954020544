.rte-output {
  color: rgba(0, 0, 0, 0.87);
}

.rte-output h1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}

.rte-output h2 {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.rte-output img {
  margin: 0 auto;
  display: block;
  max-width: 100%;
}

.rte-output .ql-align-center {
  text-align: center;
}

.rte-output pre {
  background: #333;
  white-space: pre;
  word-wrap: break-word;
  overflow: auto;
  padding: 16px 16px 16px 32px;
  font-family: monospace;
  color: '#eee';
}

.rte-output .ql-video {
  width: 100%;
  min-height: 480px;
}

.rte-output ol li {
  font-size: 16px;
  font-weight: 400;

  line-height: 24px;
}

.rte-output p {
  font-size: 14px;
  font-weight: 400;

  line-height: 20px;
}

.rte-output blockquote {
  padding: 8px;
  background: white;
  border-left: 0.5em solid #dedede;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);

  line-height: 24px;
  font-size: 14px;
}

.rte-output blockquote::before {
  display: block;
  height: 0;
  content: '“';
  margin-left: -0.95em;
  font: italic 400%/1 Cochin, Georgia, 'Times New Roman', serif;
  color: #999;
}

@media only screen and (max-width: 1200px) {
  .ql-editor .ql-video {
    width: 1280px;
    height: 480px;
    margin: 0 auto;
  }

  .rte-output blockquote {
    max-width: 760px;
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .ql-editor .ql-video {
    width: 100%;
    height: 480px;
    margin: 0 auto;
  }

  .rte-output blockquote {
    max-width: 480px;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .ql-editor .ql-video {
    width: 100%;
    height: 320px;
    margin: 0 auto;
  }

  .rte-output blockquote {
    max-width: 480px;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .ql-editor .ql-video {
    width: 100%;
    height: 280px;
    margin: 0 auto;
  }

  .rte-output blockquote {
    max-width: 320px;
  }
}
