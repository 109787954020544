.session-list-item {
  --avi-size: 100px;

  --line-size: 1px;
  --item-radius: 5px;

  --green-400: #1cad97;
  --green-500: #0d8962;
  --gray-300: #ccc;
  --gray-500: rgba(0, 0, 0, 0.87);
}

.session-list-item {
  display: grid;
  grid-template-columns: 130px 1fr minmax(220px, 0.4fr);
  border: var(--line-size) solid var(--gray-300);
  border-radius: var(--item-radius);
  margin: 1rem 0;
  padding: 1rem;
  align-items: center;
}

.session-list-item .avatar.ui.image {
  display: flex;
  width: var(--avi-size);
  height: var(--avi-size);
}

.session-list-item .info {
  display: grid;
  grid-template-rows: 30px 1fr minmax(30px, 1fr);
}

.session-list-item .info .topics,
.session-list-item .info .user,
.session-list-item .info .description {
  display: grid;
  align-items: center;
  grid-gap: 0.5rem;
}
.session-list-item .info .user {
  grid-template-columns: repeat(auto-fit, minmax(60px, max-content));
}

.session-list-item .info .topics {
  grid-template-columns: repeat(auto-fit, minmax(80px, max-content));
}

.session-list-item .info .user .name {
  font-size: 1.5rem;
}

.session-list-item .actions {
  display: grid;
  grid-template-rows: 1fr 0.5fr;
  grid-gap: 0.5rem;
  align-items: center;
  justify-items: center;
  white-space: nowrap;
}

.session-list-item .session-start-cta.ui.button {
  border-radius: 1.5rem;
  background-color: var(--green-400);
  width: calc(100% - 0.5rem);
}

.session-list-item .session-action {
  padding: 0.25rem 0.5rem;
  position: relative;
  color: var(--gray-500);
}

.session-list-item .session-action:hover {
  color: var(--green-500);
}

.session-list-item .session-action:after {
  content: '';
  position: absolute;
  width: 0px;
  height: var(--line-size);
  bottom: -6px;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.87);
  transform: scaleX(1) translate(-50%, -50%);
  transition: width 0.3s, color 0.3s;
  color: var(--green-400);
}

.session-list-item .session-action:hover:after {
  width: calc(100% - 0.5rem);
  background: var(--green-500);
}

@media (max-width: 700px) {
  .session-list-item {
    grid-template-columns: minmax(200px, 0.5fr) 1fr;
    grid-gap: 0.5rem;
  }

  .session-list-item .avi-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .session-list-item .info {
    grid-row: span 2;
    align-items: baseline;
  }

  .session-list-item .info .description {
    height: 100%;
    align-content: end;
  }
}

@media (max-width: 420px) {
  .session-list-item {
    grid-template-columns: 1fr;
  }

  .session-list-item .info {
    grid-template-rows: 40px 1fr;
  }
  .session-list-item .info .user,
  .session-list-item .info .topics,
  .session-list-item .info .description {
    justify-content: center;
    text-align: center;
    padding: 1rem;
  }
  .session-list-item .user .name {
    text-align: center;
  }
}
