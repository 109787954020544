.guide-availability {
  min-width: 800px;
}
.guide-availability > .container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.guide-availability .ui.dropdown.guide-select {
  min-width: 300px;
}

.guide-availability .ui.dropdown .menu .avi-frame {
  display: inline-block;
  margin-right: 0.5em;
}

.guide-availability .ui.dropdown .menu > .item > .image {
  width: 2em;
}

.guide-availability .availability-calendar {
  display: flex;
  flex-direction: column;
}

.guide-availability .availability-calendar .ui.header {
  text-align: center;
}

.guide-availability .availability-calendar .ui.header .avatar {
  width: 50px;
  height: 50px;
  margin-right: 1em;
}

.guide-availability .availability-calendar .controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}

.guide-availability .availability-calendar .rbc-calendar {
  align-self: stretch;
}
