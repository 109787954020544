.concierge-calls {
  display: flex;
  flex-grow: 1;
  align-self: stretch;
}

.concierge-calls .calls {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-top: 1rem;
}

.concierge-calls .calls > .header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.concierge-calls .calls > .header-row .header.ui {
  flex-grow: 1;
  margin: 0;
}

.concierge-calls .calls .ui.avatar.image img {
  width: inherit;
}
