.subchannel-card {
  padding: 0.5rem;
}

.subchannel-card .header {
  display: flex;
  justify-content: center;
  text-align: center;
  min-height: 3.5rem;
}
