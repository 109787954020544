.client-faceplate {
  display: flex;
}

.client-faceplate .client-name-avatar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.client-faceplate .client-avatar.ui.avatar.image {
  display: flex;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;
  margin-right: 1em;
}

.client-faceplate .client-avatar.image {
  display: flex;
  height: 2.6rem !important;
  width: 2.6rem !important;
}

.client-faceplate .client-avatar.image img {
  display: flex;
  border: 1px solid white;
  box-shadow: 0px 1px 6px 0px #d8d8d8;
}

.client-faceplate .client-name {
  display: flex;
  font-family: 'AvenirNextLTPro-Medium';
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3b404d;
}
