.session-tabs-component .menu {
  font-family: 'AvenirNextLTPro-Medium';
}

.session-list-item .note {
  color: #aaaaaa;
  display: block;
}

.session-tabs-component {
  width: 100%;
  max-width: 1280px;
}

.session-tabs-component .sessions-list {
  display: table;
  width: 100%;
  border-spacing: 0 1em;
}
