.registration {
  display: flex;
  flex-grow: 1;
  font-family: 'AvenirNextLTPro-Medium';
  color: #444444;
}

.registration .registration-steps {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  flex-direction: column;
}

.registration .registration-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  /* min-width: 360px; */
}

.registration .registration-step h1 {
  @apply font-bold text-3xl;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 55px;
  color: #101010;
  padding: 0rem 0.5rem;
  min-height: 8rem;
}

.registration h2 {
  font-family: 'AvenirNextLTPro-Medium';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #101010;
  padding: 0rem 3.5rem;
}
.registration .registration-step.credentials.auth0 h2 {
  margin: 1em 0;
  width: 100%;
  justify-content: space-around;
}
.registration .registration-step.credentials.auth0 .avatar {
  width: 2em;
  height: 2em;
}

.registration .registration-step > p {
  max-width: 380px;
  margin: 0.5rem 0 1.5rem 0;
  line-height: 24px;
  font-size: 15px;
}

.registration .registration-step .divider.horizontal {
  height: 0;
  text-transform: lowercase;
  margin: 2.5rem auto;
  width: 100;
}

.registration .registration-step.corporate-email-step .company-logo {
  max-width: 300px;
  margin: 0rem 0rem 3rem;
}

.registration .registration-step.spouse-invite {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  text-align: left;
  max-width: 400px;
  min-width: 300px;
}

.registration .message.confidential {
  font-size: 16px;
  line-height: 24px;
  color: #3b404d;
  padding: 1rem 1.5rem;
  background: rgba(69, 145, 129, 0.05);
  border: 1.5px solid #d0e6e0;
  box-sizing: border-box;
  border-radius: 3px;
  margin: 0rem 0rem 2rem;
}

.registration .ui.checkbox:not(.toggle) input:checked ~ .box::before,
.registration .ui.checkbox:not(.toggle) input:checked ~ label::before,
.registration .ui.checkbox:not(.toggle) input:checked:focus ~ .box::before,
.registration .ui.checkbox:not(.toggle) input:checked:focus ~ label::before {
  background: rgba(69, 145, 129, 0.5);
  border-color: rgba(69, 145, 129, 0.5);
}

.registration .ui.checkbox.toggle input:checked ~ label::before,
.registration .ui.checkbox.toggle input:checked:focus ~ label::before {
  background: rgba(69, 145, 129, 0.5) !important;
}

.registration .ui.form .fields {
  margin: 0 -0.5em 0em;
}

.registration .ui.form .field {
  margin: 0.5rem 0;
}

.registration .ui.form .field > label {
  font-size: 16px;
  line-height: 22px;
  color: #444444;
}

.registration .ui.form .ui.input:last-child,
.registration .ui.form .react-tel-input {
  margin-bottom: 1rem;
}

.registration .ui.form .fields.tos-pp-container {
  margin: 0.5rem -0.5rem;
}

@media (max-width: 980px) {
  .registration {
    width: auto;
  }

  .registration .registration-step .divider.horizontal {
    width: 90%;
  }

  .registration .registration-step.spouse-invite {
    margin: 0em 1em;
  }
}

@media (max-width: 520px) {
  .registration .spouse-invite {
    padding: 0em 1em;
  }
  .registration .registration-step.spouse-invite {
    margin: 0em;
  }
}
