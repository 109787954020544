/**
 * @license
 * MyFonts Webfont Build ID 4093776, 2021-05-25T09:52:15-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: AvenirNextLTPro-BoldIt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-bold-italic/
 * Copyright: Copyright &amp;#x00A9; 2004 - 2017 Monotype GmbH. All rights reserved.
 * 
 * Webfont: AvenirNextLTPro-DemiIt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-demi-italic/
 * Copyright: Copyright &amp;#x00A9; 2004 - 2017 Monotype GmbH. All rights reserved.
 * 
 * Webfont: AvenirNextLTPro-HeavyIt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-heavy-italic/
 * Copyright: Copyright &amp;#x00A9; 2004 - 2017 Monotype GmbH. All rights reserved.
 * 
 * Webfont: AvenirNextLTPro-It by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-italic/
 * Copyright: Copyright &amp;#x00A9; 2004 - 2017 Monotype GmbH. All rights reserved.
 * 
 * Webfont: AvenirNextLTPro-LightIt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-light-italic/
 * Copyright: Copyright &amp;#x00A9; 2012 - 2017 Monotype GmbH. All rights reserved.
 * 
 * Webfont: AvenirNextLTPro-Light by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-light/
 * Copyright: Copyright &amp;#x00A9; 2012 - 2017 Monotype GmbH. All rights reserved.
 * 
 * Webfont: AvenirNextLTPro-MediumIt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-medium-italic/
 * Copyright: Copyright &amp;#x00A9; 2004 - 2017 Monotype GmbH. All rights reserved.
 * 
 * Webfont: AvenirNextLTPro-Medium by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-medium/
 * Copyright: Copyright &amp;#x00A9; 2004 - 2017 Monotype GmbH. All rights reserved.
 * 
 * Webfont: AvenirNextLTPro-ThinIt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-thin-italic/
 * Copyright: Copyright &amp;#x00A9; 2012 - 2017 Monotype GmbH. All rights reserved.
 * 
 * Webfont: AvenirNextLTPro-UltLtIt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-ultralight-italic/
 * Copyright: Copyright &amp;#x00A9; 2004 - 2017 Monotype GmbH. All rights reserved.
 * 
 * Webfont: AvenirNextLTPro-UltLt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-ultralight/
 * Copyright: Copyright &amp;#x00A9; 2004 - 2017 Monotype GmbH. All rights reserved.
 * 
 * 
 * 
 * © 2021 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */
@import url('//hello.myfonts.net/count/3e7750');

@font-face {
  font-family: 'AvenirNextLTPro-DemiIt';
  src: url('AvenirNextLTProDemiIt/font.woff2') format('woff2'),
    url('AvenirNextLTProDemiIt/font.woff') format('woff');
}
@font-face {
  font-family: 'AvenirNextLTPro-HeavyIt';
  src: url('AvenirNextLTProHeavyIt/font.woff2') format('woff2'),
    url('AvenirNextLTProHeavyIt/font.woff') format('woff');
}
@font-face {
  font-family: 'AvenirNextLTPro-BoldIt';
  src: url('AvenirNextLTProBoldIt/font.woff2') format('woff2'),
    url('AvenirNextLTProBoldIt/font.woff') format('woff');
}
@font-face {
  font-family: 'AvenirNextLTPro-UltLt';
  src: url('AvenirNextLTProUltLt/font.woff2') format('woff2'),
    url('AvenirNextLTProUltLt/font.woff') format('woff');
}
@font-face {
  font-family: 'AvenirNextLTPro-UltLtIt';
  src: url('AvenirNextLTProUltLtIt/font.woff2') format('woff2'),
    url('AvenirNextLTProUltLtIt/font.woff') format('woff');
}
@font-face {
  font-family: 'AvenirNextLTPro-It';
  src: url('AvenirNextLTProIt/font.woff2') format('woff2'),
    url('AvenirNextLTProIt/font.woff') format('woff');
}
@font-face {
  font-family: 'AvenirNextLTPro-LightIt';
  src: url('AvenirNextLTProLightIt/font.woff2') format('woff2'),
    url('AvenirNextLTProLightIt/font.woff') format('woff');
}
@font-face {
  font-family: 'AvenirNextLTPro-MediumIt';
  src: url('AvenirNextLTProMediumIt/font.woff2') format('woff2'),
    url('AvenirNextLTProMediumIt/font.woff') format('woff');
}
@font-face {
  font-family: 'AvenirNextLTPro-ThinIt';
  src: url('AvenirNextLTProThinIt/font.woff2') format('woff2'),
    url('AvenirNextLTProThinIt/font.woff') format('woff');
}
@font-face {
  font-family: 'AvenirNextLTPro-Medium';
  src: url('AvenirNextLTProMedium/font.woff2') format('woff2'),
    url('AvenirNextLTProMedium/font.woff') format('woff');
}
@font-face {
  font-family: 'AvenirNextLTPro-Light';
  src: url('AvenirNextLTProLight/font.woff2') format('woff2'),
    url('AvenirNextLTProLight/font.woff') format('woff');
}

.AvenirNextLTProBoldIt {
  font-family: 'AvenirNextLTPro-BoldIt';
}
.AvenirNextLTProDemiIt {
  font-family: 'AvenirNextLTPro-DemiIt';
}
.AvenirNextLTProHeavyIt {
  font-family: 'AvenirNextLTPro-HeavyIt';
}
.AvenirNextLTProIt {
  font-family: 'AvenirNextLTPro-It';
}
.AvenirNextLTProLightIt {
  font-family: 'AvenirNextLTPro-LightIt';
}
.AvenirNextLTProLight {
  font-family: 'AvenirNextLTPro-Light';
}
.AvenirNextLTProMediumIt {
  font-family: 'AvenirNextLTPro-MediumIt';
}
.AvenirNextLTProMedium {
  font-family: 'AvenirNextLTPro-Medium';
}
.AvenirNextLTProThinIt {
  font-family: 'AvenirNextLTPro-ThinIt';
}
.AvenirNextLTProUltLtIt {
  font-family: 'AvenirNextLTPro-UltLtIt';
}
.AvenirNextLTProUltLt {
  font-family: 'AvenirNextLTPro-UltLt';
}
