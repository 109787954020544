.call-request-form-bookingtime-selector {
  display: flex;
  flex-grow: 1;
}

.call-request-form-bookingtime-selector .calendar-selector-inlinecalendar {
  min-width: 330px;
  padding-left: 0 !important;
  justify-content: flex-start !important;
}

.call-request-form-bookingtime-selector .react-datepicker__day--selected span {
  color: #ffffff !important;
}
