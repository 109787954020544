@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: 'AvenirNextLTPro-Medium', sans-serif;
}

date-input-polyfill {
  z-index: 1002;
}
