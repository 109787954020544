.roadmap-item.ui.card {
  padding: 0.5rem;
}

.roadmap-item.ui.card .roadmap-content.content {
  height: 14rem;
  display: flex;
  flex-direction: column;
}

.roadmap-item.ui.card .roadmap-content.content .roadmap-content-header {
  display: flex;
  min-height: 3.5rem;
  align-content: center;
  justify-content: center;
  text-align: center;
}

.roadmap-item.ui.card .roadmap-content.content .description h2 {
  font-size: 1.1rem;
}

.roadmap-item.ui.card .roadmap-content.content .roadmap-content-body {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.roadmap-item.ui.card .roadmap-content.content .roadmap-content-body:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: -4.5rem;
  background: linear-gradient(transparent 150px, white);
}

.roadmap-item.ui.card .roadmap-tags-container.extra.content {
  display: flex;
  min-height: 4.5rem !important;
}

.roadmap-item.ui.card .roadmap-tags-title {
  color: rgba(0, 0, 0, 0.85);
  margin-right: 0.5rem;
}

@media screen and (max-width: 375px) {
  .resource-roadmaps .roadmap-item.ui.card {
    max-width: 320px !important;
  }
}

@media screen and (max-width: 320px) {
  .resource-roadmaps .roadmap-item.ui.card {
    max-width: 290px !important;
  }
}
